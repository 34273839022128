import Vue from 'vue'
import callsService from '../services/api/calls'

const callModule = {
    state: {
        channels: [],
        bridges: [],
        calls: [],
        myCalls: [],
        channelToTransfer: '',
        channelToRedirect: '',
        channelInTransfer: ''
    },
    mutations: {
        setChannels (state, channels) {
            state.channels = channels
        },
        addChannel (state, channel) {
            let index = state.channels.findIndex(x => x.channel === channel.channel)

            if(index == -1) {
                state.channels.push(channel)
            }
        },
        updateChannel (state, channel) {
            let index = state.channels.findIndex(x => x.channel === channel.channel)

            if(index > -1) {
                Vue.set(state.channels, index, channel)
            }
        },
        deleteChannel (state, channel) {
            let index = state.channels.findIndex(x => x.channel === channel.channel)

            if(index > -1) {
                state.channels.splice(index,1)
            }
        },
        deleteChannelByIndex (state, index) {
            if(index > -1) {
                state.channels.splice(index,1)
            }
        },
        setBridges (state, bridges) {
            state.bridges = bridges
        },
        addBridge (state, bridge) {
            let index = state.bridges.findIndex(x => x.id === bridge.id)

            if(index == -1) {
                state.bridges.push(bridge)
            }
        },
        updateBridge (state, bridge) {
            let index = state.bridges.findIndex(x => x.id === bridge.id)

            if(index > -1) {
                Vue.set(state.bridges, index, bridge)
            }
        },
        deleteBridge (state, bridge) {
            let index = state.bridges.findIndex(x => x.id === bridge.id)

            if(index > -1) {
                state.bridges.splice(index,1)
            }
        },
        deleteBridgeByIndex (state, index) {
            if(index > -1) {
                state.bridges.splice(index,1)
            }
        },
        setCalls(state, calls) {
            state.calls = calls
        },
        addCall(state, call) {
            state.calls.push(call)
        },
        updateCall(state, payload) {
            if(payload.index > -1) {
                Vue.set(state.calls, payload.index, payload.call)
            }
        },
        deleteCallByIndex(state, index) {
            if(index > -1) {
                state.calls.splice(index, 1)
            }
        },
        setMyCalls(state, myCalls) {
            state.myCalls = myCalls
        },
        addMyCall(state, call) {
            state.myCalls.push(call)
        },
        updateMyCall(state, payload) {
            if(payload.index > -1) {
                Vue.set(state.myCalls, payload.index, payload.call)
            }
        },
        deleteMyCallByIndex(state, index) {
            if(index > -1) {
                state.myCalls.splice(index, 1)
            }
        },
        setChannelToTransfer (state, channelToTransfer) {
            state.channelToTransfer = channelToTransfer
        },
        setChannelToRedirect (state, channelToRedirect) {
            state.channelToRedirect = channelToRedirect
        },
        setChannelInTransfer (state, channelInTransfer) {
            state.channelInTransfer = channelInTransfer
        },
    },
    actions: {
        hangupChannel(context, channel) {
            return new Promise((resolve, reject) => {
                callsService.hangupChannel({channel: channel})
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        parkChannel(context, channel) {
            return new Promise((resolve, reject) => {
                callsService.parkChannel({channel: channel})
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        redirectChannel(context, payload) {
            return new Promise((resolve, reject) => {
                callsService.redirectChannel(payload)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        attendedtransferChannel(context, payload) {
            return new Promise((resolve, reject) => {
                callsService.attendedtransferChannel(payload)
                    .then((res) => {
                        context.commit("setChannelInTransfer", payload.channel)
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        cancelAttendedtransferChannel(context, payload) {
            return new Promise((resolve, reject) => {
                callsService.cancelAttendedtransferChannel(payload)
                    .then((res) => {
                        context.commit("setChannelInTransfer", '')
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
    }
}

export default callModule