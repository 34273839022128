<template lang="pug">
  .fop
    .header-actions
      .header-actions-content(style="z-index: 100")
        .d-flex.flex-row
          .p-2
            filter-department(@updateDepartmentIds="updateDepartmentIds")
          .p-2
            .input-group
              button.btn.btn-secondary
                font-awesome-icon(icon="filter")
              input.form-control(type="text" placeholder="Buscar usuario" v-model="filter")
    .main-content
      .container-fluid
        .row
          .col-xxl-9.col-xl-9.col-lg-6
            .card
              .card-body
                .alert.bg-warning-opacity-25(role="alert" v-if="channelInTransfer")
                  .d-flex
                    span Transferencia en curso, pulse la cruz para recuperar la llamada
                    button.btn-close.btn-close-dark.me-2.m-auto(type="button" aria-label="Close" @click="cancelAttendedTransfer()")
                .alert.bg-primary-opacity-25(role="alert" v-if="channelToTransfer")
                  .d-flex
                    span Para transferir la llamada pulse sobre el usuario deseado
                    button.btn-close.btn-close-dark.me-2.m-auto(type="button" aria-label="Close" @click="setChannelToTransfer(null)")
                .alert.bg-primary-opacity-25(role="alert" v-if="channelToRedirect")
                  .d-flex
                    span Para redirigir la llamada pulse sobre el usuario deseado
                    button.btn-close.btn-close-dark.me-2.m-auto(type="button" aria-label="Close" @click="setChannelToRedirect(null)")
                .row.row-cols-xxl-5.row-cols-xl-3.row-cols-lg-3.row-cols-md-3
                  .col(v-for="user in filteredUsers" :key="'user' + user.id")
                    user-status(
                      :user="user" 
                      :calls="userCalls[user.id] ? userCalls[user.id].calls : []" 
                      :showCalls="true" 
                    )
          .col-xxl-3.col-xl-3.col-lg-6
            nav.nav.mb-2
              a.nav-link(:class="tabItem == 'queue' ? 'active' : ''" @click="setTabItem('queue')") En cola
                span.badge.rounded-pill.bg-primary.ms-1(v-if="totalOnQueueCalls") {{totalOnQueueCalls}}
              a.nav-link.position-relative(:class="tabItem == 'parking' ? 'active' : ''" @click="setTabItem('parking')") Parking
                span.badge.rounded-pill.bg-primary.ms-1(v-if="totalOnParkingCalls") {{totalOnParkingCalls}}
            .card
              .card-body
                div(v-if="tabItem =='queue'")
                  strong LLamadas en cola
                  queue-calls
                div(v-if="tabItem =='parking'")
                  strong Llamadas aparcadas
                  parking-calls
                

</template>

<script>
  import UserStatus from '../../components/users/UserStatus.vue'
  import QueueCalls from '../../components/calls/QueueCalls.vue'
  import ParkingCalls from '../../components/calls/ParkingCalls.vue'
  import FilterDepartment from '../../components/departments/FilterDepartment.vue'
  export default {
    name: 'UsersFop',
    components: {
      UserStatus,
      QueueCalls,
      ParkingCalls,
      FilterDepartment
    },
    data () {
      return {
        tabItem: 'queue',
        departmentsId: [],
        filter: '',
      }
    },
    computed: {
      users() {
        return this.$store.state.userModule.users
      },
      filteredDepartmentUsers() {
        if(this.departmentsId.length > 0) {
          return this.users.filter((u) => {
            return this.departmentsId.some( (d) => d == u.department_id);
          })  
        } else {
          return this.users
        }
      },
      filteredUsers() {
        return this.filteredDepartmentUsers.filter((u) => {
          return (u.name.toLowerCase().includes(this.filter) || (u.extension && u.extension.number.includes(this.filter)));
        })
      },
      calls() {
        return this.$store.state.callModule.calls
      },
      onQueueCalls() {
        return this.calls.filter((c) => {return c.queue})
      },
      totalOnQueueCalls() {
        return this.onQueueCalls.length
      },
      onParkingCalls() {
        return this.calls.filter((c) => {return c.parked})
      },
      totalOnParkingCalls() {
        return this.onParkingCalls.length
      },
      channelToRedirect() {
        return this.$store.state.callModule.channelToRedirect
      },
      channelToTransfer() {
        return this.$store.state.callModule.channelToTransfer
      },
      channelInTransfer() {
        return this.$store.state.callModule.channelInTransfer
      },
      userCalls() {
        let userCalls = {}

        this.calls.forEach((call) => {
          call.connected.forEach((connected) => {
            let index = this.users.findIndex((u) => u.extension && u.extension.number == connected)

            if(index > -1) {
              if(!userCalls[this.users[index].id]) {
                userCalls[this.users[index].id] = {}
                this.$set(userCalls[this.users[index].id], 'calls', [])
              }

              userCalls[this.users[index].id].calls.push(call)
            }
          })
        })

        return userCalls
      }
    },
    beforeMount() {
      
    },
    methods: {
      updateDepartmentIds(event) {
        this.departmentsId = event.departments_id
      },
      setTabItem(tabItem) {
        this.tabItem = tabItem
      },
      cancelAttendedTransfer() {
        this.$store.dispatch("cancelAttendedtransferChannel", {
          channel: this.channelInTransfer,
        })
      },
      setChannelToTransfer() {
        this.$store.commit("setChannelToTransfer", '')
      },
      setChannelToRedirect() {
        this.$store.commit("setChannelToRedirect", '')
      }
    }
  }
</script>
<style lang="scss">
</style>