<template lang="pug">
.card.p-1.mt-2.small(:class="getUserBackground(user)")
    .card-body.p-0.mb-0.text-truncate
      .row.g-0.m-0.p-0.d-flex.align-items-center(@click="redirectChannelToTransfer()")
        .col-md-3
          img.mx-auto.d-block(src="https://dummyimage.com/50x60/868e96/000000.png"  alt="")
        .col-md-9.p-1
          .d-flex.pb-0.mb-0
            .me-auto
              small 
                span.badge.bg-primary(v-if="calls.length > 0") En uso
                span.badge.bg-warning(v-else-if="user.active_rest_time") {{user.active_rest_time.rest_time.name}}
                span.badge.bg-success(v-else-if="user.active_user_session") Disponible
                span.badge.bg-danger(v-else) Desconectado
            div(v-if="user.extension")
              small
                font-awesome-icon.me-1.fa-sm(:class="getExtensionStatusColor(user.extension)" :icon="['fas', 'circle']")
                span {{user.extension.number}}
          strong.d-block.mb-0 {{user.name}}
          small.d-block.mb-1(v-if="user.department_id") {{user.department.name}}
          small.d-block.mb-1(v-else) &nbsp;
      div(v-if="showCalls")
        call-item(
          v-for="call in calls" 
          :call="call" 
          :user="user" 
        )

</template>

<script>
  import CallItem from '../calls/CallItem.vue'
  export default {
    name: 'UserStatus',
    components: {
      CallItem
    },
    props: { 
      user: Object,
      calls: Array,
      showCalls: {type: Boolean, default: false },
    },
    data () {
      return {
        callHover: null
      }
    },
    computed: {
      counterKey() {
        return this.$store.state.counterKey
      },
      channelToRedirect() {
        return this.$store.state.callModule.channelToRedirect
      },
      channelToTransfer() {
        return this.$store.state.callModule.channelToTransfer
      },
    },
    beforeMount() {
      
    },
    methods: {
      setCallHover(call) {
        this.callHover = call
      },
      getUserBackground() {
        let background = 'bg-white';

        if(this.calls.length > 0) {
          background = 'bg-primary-opacity-25'
        }
        else if(this.user.active_rest_time) {
          background = 'bg-warning-opacity-25'
        }
        else if(this.user.active_user_session) {
          background = 'bg-success-opacity-25'
        } else {
          background = 'bg-danger-opacity-25'
        }

        return background
      },
      getExtensionStatusColor(extension) {
        let color = 'text-dark'

        switch(extension.extension_status_id) {
          case 1:
              color = 'text-dark';
            break;
          case 2:
              color = 'text-dark';
            break;
          case 3:
              color = 'text-success';
            break;
          case 4:
              color = 'text-primary';
            break;
          case 5:
              color = 'text-warning';
            break;
          case 6:
              color = 'text-danger';
            break;
          case 7:
              color = 'text-info blink';
            break;
          case 8:
              color = 'text-primary blink';
            break;
          case 9:
              color = 'text-warning';
            break;
          case 10:
              color = 'text-warning';
            break;
          case 11:
              color = 'text-dark';
            break;
          case 12:
              color = 'text-dark';
            break;
        }

        return color
      },
      redirectChannelToTransfer() {
        if(this.channelToRedirect && this.user.extension && this.user.extension.number) {
            let data = {
              channel: this.channelToRedirect,
              extension: this.user.extension.number
            }
            this.$store.dispatch("redirectChannel", data)
            this.$store.commit("setChannelToRedirect", '')
        } else if(this.channelToTransfer && this.user.extension && this.user.extension.number) {
            let data = {
              channel: this.channelToTransfer,
              extension: this.user.extension.number
            }
            this.$store.dispatch("attendedtransferChannel", data)
            this.$store.commit("setChannelToTransfer", '')
        }
      }
    }
  }
</script>
<style lang="scss">
</style>