import appService from "./appService";

const callsService = {
    hangupChannel(data) {
        return appService.post('/calls/hangup-channel', data)
            .then((res) => res.data)
    },
    parkChannel(data) {
        return appService.post('/calls/park-channel', data)
            .then((res) => res.data)
    },
    redirectChannel(data) {
        return appService.post('/calls/redirect-channel', data)
            .then((res) => res.data)
    },
    attendedtransferChannel(data) {
        return appService.post('/calls/attendedtransfer-channel', data)
            .then((res) => res.data)
    },
    cancelAttendedtransferChannel(data) {
        return appService.post('/calls/cancel-attendedtransfer-channel', data)
            .then((res) => res.data)
    },
}

export default callsService;