<template lang="pug">
  #app(:class="darkTheme ? 'dark-theme' : ''")
    template(v-if="user && user.id")
      Header(:darkTheme="darkTheme" @toogleTheme="toogleTheme()")
    router-view
</template>

<script>
import Header from './components/Header.vue'

export default {
  name: 'App',
  components: {
    Header
  },
  data () {
    return {
      user: {},
      darkTheme: false
    }
  },
  computed: {
    counterKey() {
      return this.$store.state.counterKey
    }
  },
  created() {
    const html = document.documentElement // returns the html tag
    html.setAttribute('lang', 'es')
  
    if(localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'))
      this.user = user.user

      this.$store.commit("setUser", user.user)
      this.$store.commit("setCurrentUser", user.user)
      this.$store.commit("setBackendUrl", user.app_url)

      setInterval(()=> {
        this.$store.commit("setCounterKey", this.counterKey + 1);
      }, 1000)
    }
  },
  methods: {
    toogleTheme(event) {
      console.log(event)
      this.darkTheme = !this.darkTheme
    }
  }
}
</script>

<style lang="scss">
  @import "vue-select/src/scss/vue-select.scss";
  @import './scss/main.scss';
</style>
