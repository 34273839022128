import Vue from 'vue'
import Vuex from 'vuex'

import authModule from "./auth"
import moduleModule from "./modules"
import accountModule from "./accounts"
import legalDocumentModule from "./legal_documents"
import taskModule from "./tasks"
import productModule from "./products"
import callModule from "./calls"
import userModule from "./users"
import restTimeModule from "./rest_times"
import extensionModule from "./extensions"
import departmentModule from "./departments"

import reportService from "../services/api/reports"
import authService from "../services/api/auth"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        currentUser: {},
        counterKey: 0
    },
    mutations: {
        setCurrentUser (state, currentUser) {
            state.currentUser = currentUser
        },
        setCounterKey (state, counterKey) {
            state.counterKey = counterKey
        },
    },
    actions: {
        setCurrentUser(context) {
            return new Promise((resolve, reject) => {
                authService.me()
                    .then(user => {
                        context.commit('setCurrentUser', user)
                        resolve(user)
                    })
                    .catch(reject)
            })
        },
        getReport (context, payload) {
            return new Promise((resolve, reject) => {
                reportService.getReportSample(payload)
                    .then((data) => {
                        resolve(data)
                    })
                    .catch(reject)
            })
        },
    },
    modules: {
        authModule,
        moduleModule,
        accountModule,
        taskModule,
        legalDocumentModule,
        productModule,
        callModule,
        userModule,
        restTimeModule,
        extensionModule,
        departmentModule
    }
})