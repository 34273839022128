<template lang="pug">
  .pbx-show
    .container-fluid
      .cont-zoom
        input.form-range(type="range" min="0" max="100" step="10" v-model="zoom")
      .row
        .col-sm-3.bg-white
          .lateral-menu
            .lateral-menu-header
              input.form-control(placeholder="Buscar" v-model="filter")
              select.form-select(v-model="filterGroup")
                option(value="") Todos
                option(value="Announcement") Anuncios
                option(value="Queue") Colas
                option(value="TimeCondition") Condiciones Horarias
                option(value="Extensions") Extensiones
                option(value="RingGroup") Grupos de timbrado
                option(value="IVR") IVRs
            .lateral-menu-body
              .card
                ul.list-group.list-group-flush
                  flowy-new-block(
                    v-for="(block, index) in sortedBlocks"
                    :key="index"
                    @drag-start="onDragStartNewBlock"
                    @drag-stop="onDragStopNewBlock"
                  )
                    template(v-slot:preview="{}")
                      demo-block(
                        :title="block.preview.title"
                        :description="block.preview.description"
                        :icon="block.destinationNode.data.icon"
                      )
                    template(v-slot:node="{}")
                      demo-node(
                        :title="block.node.title"
                        :description="block.node.description"
                        :custom-attribute="block.node.canBeAdded"
                      )
        .col-sm-9
          .content-diagram(:class="'zoom' + zoom")
            flowy.q-mx-auto(
              v-dragscroll="backgroundDrag"
              :nodes="sortedNodes"
              :beforeMove="beforeMove"
              :beforeAdd="beforeAdd"
              @add="add"
              @move="move"
              @remove="remove"
              @drag-start="onDragStart"
            )
</template>

<script>

  export default {
    name: 'PbxShow',
    beforeMount() {
      const component = this;
      this.handlerUp = ((e) => {
        console.log(e.keyCode)
        if(e.keyCode == 17) {
          this.backgroundDrag = false
        }
        //component.$emit('keyup', e);
      })
      window.addEventListener('keyup', this.handlerUp);

      this.handlerDown = ((e) => {
        console.log(e.keyCode)
        if(e.keyCode == 17) {
          this.backgroundDrag = true
        }
        component.$emit('keydown', e);
      })
      window.addEventListener('keydown', this.handlerDown);
    },
    data () {
      return {
        holder: [],
        zoom: 100,
        filter: '', 
        filterGroup: '',
        dragging: false,
        backgroundDrag: false,
        blocks: [
          {
            id: '1',
            preview: {
              title: 'Festivo Barcelona',
              type: 'TimeCondition',
            },
            node: {
              title: 'Festivos Barcelona',
              description: '1',
            },
            destinationNode: {
              nodeComponent: 'demo-node',
              data: {
                text: 'Parent block',
                icon: 'clock',
                type: 'TimeCondition',
                title: 'Festivos Barcelona',
              },
            }
          },
          {
            id: '2',
            preview: {
              title: 'Festivo Cataluña',
              type: 'TimeCondition',
            },
            node: {
              title: 'Festivos Cataluña',
              description: '2',
            },
            destinationNode: {
              nodeComponent: 'demo-node',
              data: {
                text: 'Parent block',
                icon: 'clock',
                type: 'TimeCondition',
                title: 'Festivos Cataluña',
              },
            }
          },
          {
            id: '3',
            preview: {
              title: 'Festivo España',
              type: 'TimeCondition',
            },
            node: {
              title: 'Festivos España',
              description: '3',
            },
            destinationNode: {
              nodeComponent: 'demo-node',
              data: {
                text: 'Parent block',
                icon: 'clock',
                type: 'TimeCondition',
                title: 'Festivos España',
              },
            }
          },
          {
            id: '4',
            preview: {
              title: 'Festivo España',
              type: 'TimeCondition',
            },
            node: {
              title: 'Festivos España',
              description: '4',
            },
            destinationNode: {
              nodeComponent: 'demo-node',
              data: {
                text: 'Parent block',
                icon: 'clock',
                type: 'TimeCondition',
                title: 'Festivos España',
              },
            }
          },
          {
            id: '5',
            preview: {
              title: 'Horario General',
              type: 'TimeCondition',
            },
            node: {
              title: 'Horario General',
              description: '5',
            },
            destinationNode: {
              nodeComponent: 'demo-node',
              data: {
                text: 'Parent block',
                icon: 'clock',
                type: 'TimeCondition',
                title: 'Horario General',
              },
            }
          },
          {
            id: '13',
            preview: {
              title: 'Locución festivo',
              type: 'Announcement',
            },
            node: {
              title: 'Locución festivo',
              description: '13',
            },
            destinationNode: {
              nodeComponent: 'demo-node',
              data: {
                text: 'Parent block',
                icon: 'volume-up',
                type: 'Announcement',
                title: 'Locución festivo',
              },
            }
          },
          {
            id: '6',
            preview: {
              title: 'Locución fuera de horario',
              type: 'Announcement',
            },
            node: {
              title: 'Locución fuera de horario',
              description: '6',
            },
            destinationNode: {
              nodeComponent: 'demo-node',
              data: {
                text: 'Parent block',
                icon: 'volume-up',
                type: 'Announcement',
                title: 'Locución fuera de horario',
              },
            }
          },
          {
            id: '7',
            preview: {
              title: 'Menú Opciones Barcelona',
              type: 'IVR',
            },
            node: {
              title: 'Menú Opciones Barcelona',
              description: '7',
            },
            destinationNode: {
              nodeComponent: 'demo-node',
              data: {
                text: 'Parent block',
                icon: 'list-ol',
                type: 'IVR',
                title: 'Menú Opciones Barcelona',
              },
            }
          },
          {
            id: '8',
            preview: {
              title: 'Cola Operadora',
              type: 'Queue',
            },
            node: {
              title: 'Cola Operadora',
              description: '8',
            },
            destinationNode: {
              nodeComponent: 'demo-node',
              data: {
                text: 'Parent block',
                icon: 'sitemap',
                type: 'Queue',
                title: 'Cola Operadora',
              },
            }
          },
          {
            id: '9',
            preview: {
              title: 'Cola Administración',
              type: 'Queue',
            },
            node: {
              title: 'Cola Administración',
              description: '9',
            },
            destinationNode: {
              nodeComponent: 'demo-node',
              data: {
                text: 'Parent block',
                icon: 'sitemap',
                type: 'Queue',
                title: 'Cola Administración',
              },
            }
          },
          {
            id: '10',
            preview: {
              title: 'Cola Comercial',
              type: 'Queue',
            },
            node: {
              title: 'Cola Comercial',
              description: '10',
            },
            destinationNode: {
              nodeComponent: 'demo-node',
              data: {
                text: 'Parent block',
                icon: 'sitemap',
                type: 'Queue',
                title: 'Cola Comercial',
              },
            }
          },
          {
            id: '11',
            preview: {
              title: 'Cola Técnicos',
              type: 'Queue',
            },
            node: {
              title: 'Cola Técnicos',
              description: '11',
            },
            destinationNode: {
              nodeComponent: 'demo-node',
              data: {
                text: 'Parent block',
                icon: 'sitemap',
                type: 'Queue',
                title: 'Cola Técnicos',
              },
            }
          },
          {
            id: '12',
            preview: {
              title: 'Grupo de timbrado Técnicos',
              type: 'RingGroup',
            },
            node: {
              title: 'Grupo de timbrado Técnicos',
              description: '12',
            },
            destinationNode: {
              nodeComponent: 'demo-node',
              data: {
                text: 'Parent block',
                icon: 'bell',
                type: 'RingGroup',
                title: 'Grupo de timbrado Técnicos',
              },
            }
          },
        ],
        nodes: [
          {
            id: '1',
            parentId: -1,
            nodeComponent: 'demo-node',
            data: {
              text: 'Parent block',
              icon: 'phone',
              type: 'RouteIn',
              title: 'Cabecera Barcelona 1',
              description: 'Telf: 934123456',
            },
          },
          {
            id: '2',
            parentId: '1',
            nodeComponent: 'demo-node',
            data: {
              text: 'Parent block',
              icon: 'clock',
              type: 'TimeCondition',
              title: 'Festivos Barcelona',
              description: '',
            },
          },
          {
            id: '3',
            parentId: '2',
            parentOption: 'Si',
            nodeComponent: 'demo-node',
            data: {
              text: 'Parent block',
              icon: 'volume-up',
              type: 'Announcement',
              title: 'Locución fuera de horario',
              description: '',
            },
          },
          {
            id: '4',
            parentId: '2',
            parentOption: 'No',
            nodeComponent: 'demo-node',
            data: {
              text: 'Parent block',
              icon: 'clock',
              type: 'TimeCondition',
              title: 'Festivos Cataluña',
              description: '',
            },
          },
          {
            id: '5',
            parentId: '4',
            parentOption: 'Si',
            nodeComponent: 'demo-node',
            data: {
              text: 'Parent block',
              icon: 'volume-up',
              type: 'Announcement',
              title: 'Locución fuera de horario',
              description: '',
            },
          },
          {
            id: '6',
            parentId: '4',
            parentOption: 'No',
            nodeComponent: 'demo-node',
            data: {
              text: 'Parent block',
              icon: 'clock',
              type: 'TimeCondition',
              title: 'Festivos España',
              description: '',
            },
          },
          {
            id: '7',
            parentId: '6',
            parentOption: 'Si',
            nodeComponent: 'demo-node',
            data: {
              text: 'Parent block',
              icon: 'volume-up',
              type: 'Announcement',
              title: 'Locución fuera de horario',
              description: '',
            },
          },
          {
            id: '8',
            parentId: '6',
            parentOption: 'No',
            nodeComponent: 'demo-node',
            data: {
              text: 'Parent block',
              icon: 'clock',
              type: 'TimeCondition',
              title: 'Horario General',
              description: '',
            },
          },
          {
            id: '9',
            parentId: '8',
            parentOption: 'Si',
            nodeComponent: 'demo-node',
            data: {
              text: 'Parent block',
              icon: 'list-ol',
              type: 'IVR',
              title: 'Menú Opciones Barcelona',
              description: '',
            },
          },
          {
            id: '10',
            parentId: '8',
            parentOption: 'No',
            nodeComponent: 'demo-node',
            data: {
              text: 'Parent block',
              icon: 'volume-up',
              type: 'Announcement',
              title: 'Locución fuera de horario',
              description: '',
            },
          },
          {
            id: '11',
            parentId: '9',
            parentOption: '0',
            nodeComponent: 'demo-node',
            data: {
              text: 'Cola Operadoras',
              icon: 'sitemap',
              type: 'Queue',
              title: 'Cola Operadora',
              description: '',
            },
          },
          {
            id: '12',
            parentId: '9',
            parentOption: '1',
            nodeComponent: 'demo-node',
            data: {
              text: 'Cola Administración',
              icon: 'sitemap',
              type: 'Queue',
              title: 'Cola Administración',
              description: '',
            },
          },
          {
            id: '13',
            parentId: '9',
            parentOption: '2',
            nodeComponent: 'demo-node',
            data: {
              text: 'Cola Comercial',
              icon: 'sitemap',
              type: 'Queue',
              title: 'Cola Comercial',
              description: '',
            },
          },
          {
            id: '14',
            parentId: '9',
            parentOption: '3',
            nodeComponent: 'demo-node',
            data: {
              text: 'Cola Técnicos',
              icon: 'sitemap',
              type: 'Queue',
              title: 'Cola Técnicos',
              description: '',
            },
          },
          {
            id: '15',
            parentId: '9',
            parentOption: 'Timeout',
            nodeComponent: 'demo-node',
            data: {
              text: 'Cola Operadoras',
              icon: 'sitemap',
              type: 'Queue',
              title: 'Cola Operadoras',
              description: '',
            },
          },
        ]
      }
    },
    computed: {
      filteredBlocks() {
        return this.blocks.filter(block => {
          if(this.filterGroup) {
            return (block.destinationNode.data.title.toLowerCase().includes(this.filter) && block.destinationNode.data.type == this.filterGroup);
          } else {
            return block.destinationNode.data.title.toLowerCase().includes(this.filter);
          }
        });
      },
      sortedBlocks() {
        function compare(a, b) {
          if (a.preview.title < b.preview.title)
            return -1;
          if (a.preview.title > b.preview.title)
            return 1;
          return 0;
        }

        return [...this.filteredBlocks].sort(compare);
      },
      sortedNodes() {
        function compare(a, b) {
          if (a.parentId < b.parentId)
            return -1;
          if (a.parentId > b.parentId)
            return 1;
          if (a.parentOption < b.parentOption)
            return -1;
          if (a.parentOption > b.parentOption)
            return 1;
          return 0;
        }

        return [...this.nodes].sort(compare);
      }
    },
    methods: {
      onDragStartNewBlock (event) {
        console.log('onDragStartNewBlock', event);
        // contains all the props and attributes passed to demo-node
        const { props } = event
        this.newDraggingBlock = props;
      },
      onDragStopNewBlock (event) {
        console.log('onDragStopNewBlock', event);
        this.newDraggingBlock = null;
      },
      // REQUIRED
      beforeMove ({ to, from }) {
        // called before moving node (during drag and after drag)
        // indicator will turn red when we return false
        // from is null when we're not dragging from the current node tree
        console.log('beforeMove', to, from);

        // we cannot drag upper parent nodes in this demo
        if (from && from.parentId === -1) {
          return false;
        }
        // we're adding a new node (not moving an existing one)
        if (from === null) {
          // we've passed this attribute to the demo-node
          if (this.newDraggingBlock['custom-attribute'] === false) {
            return false
          }
        }

        return true;
      },
      // REQUIRED
      beforeAdd ({ to, from }) {
        // called before moving node (during drag and after drag)
        // indicator will turn red when we return false
        // from is null when we're not dragging from the current node tree
        console.log('beforeAdd', to, from);

        // we've passed this attribute to the demo-node
        if (this.newDraggingBlock['custom-attribute'] === false) {
          return false
        }

        return true;
      },
      randomInteger () {
        return Math.floor(Math.random() * 10000) + 1;
      },
      generateId (nodes) {
        let id = this.randomInteger();
        // _.find is a lodash function
        if(nodes) {
          while (nodes.findIndex({id: id}) > -1) {
            id = this.randomInteger();
          }
        }
        return id;
      },
      addNode (event) {
        console.log('addNoe', event)
        const id = this.generateId();
        this.nodes.push({
          ...event.node,
          id,
        });
      },
      remove (event) {
        // node we're dragging to
        let node = event.node

        // we use lodash in this demo to remove node from the array
        let nodeIndex = this.nodes.findIndex((x) => x.id == node.id);
        this.nodes.splice(nodeIndex, 1);
      },
      move (event) {
        // node we're dragging to and node we've just dragged
        const { dragged, to } = event;

        //RC: Obtenemos las opciones ocupadas
        let currentOptions = []
        this.nodes.forEach((node) => {
          if(node.parentId == to.id) {
            currentOptions.push(node.parentOption)
          }
        })

        let defaultOptions = []
        let options = {}

        if(to.data.type == 'Announcement') {
          //RC: Seteamos las opciones disponibles
          defaultOptions = [' ']

          defaultOptions.forEach((i) => {
            if(currentOptions.findIndex(n => n == i) == -1) {
              this.$set(options, i, i)
            }
          })

        } else if(to.data.type == 'TimeCondition') {
          //RC: Seteamos las opciones disponibles
          defaultOptions = ['No', 'Si']

          defaultOptions.forEach((i) => {
            if(currentOptions.findIndex(n => n == i) == -1) {
              this.$set(options, i, i)
            }
          })
        } else if(to.data.type == 'Queue') {
          //RC: Seteamos las opciones disponibles
          defaultOptions = ['Timeout']

          defaultOptions.forEach((i) => {
            if(currentOptions.findIndex(n => n == i) == -1) {
              this.$set(options, i, i)
            }
          })
        } else if(to.data.type == 'RingGroup') {
          //RC: Seteamos las opciones disponibles
          defaultOptions = ['Timeout']

          defaultOptions.forEach((i) => {
            if(currentOptions.findIndex(n => n == i) == -1) {
              this.$set(options, i, i)
            }
          })

        } else if(to.data.type == 'IVR') {
          
          //RC: Seteamos las opciones disponibles
          defaultOptions = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Error', 'Timeout']

          defaultOptions.forEach((i) => {
            if(currentOptions.findIndex(n => n == i) == -1) {
              this.$set(options, i, i)
            }
          })

        } else {
          // change panentId to id of node we're dragging to
          dragged.parentId = to.id; 
        }

        //RC: Si tenemos opciones mostramos las opciones, sino mostramos la alerta
        if(currentOptions.length < defaultOptions.length) {
          if(defaultOptions.length > 1) {
            this.$swal.fire({
              title: 'Seleccione una opción del menú para guardar',
              icon: 'info',
              input: 'select',
              inputLabel: 'Seleccione una opción',
              inputOptions: options,
              showCancelButton: true
            }).then((result) => {
              
              if(result.isConfirmed) {
                dragged.parentOption = result.value
                // change panentId to id of node we're dragging to
                dragged.parentId = to.id; 
              }
            });
          } else {
            dragged.parentOption = defaultOptions[0]
            // change panentId to id of node we're dragging to
            dragged.parentId = to.id; 
          }
        } else {
          this.$swal.fire({
            title: 'No hay opciones disponibles. Primero debe borrar una de las opciones.',
            icon: 'danger',
            showCancelButton: false
          })
        }
      },
      add (event) {
        console.log('add', event)

        let addIndex = this.blocks.findIndex(b => b.id == event.node.data.description)

        if(addIndex > -1) {

          let destinationNode = this.blocks[addIndex].destinationNode

          this.$set(destinationNode, 'parentId', event.node.parentId)

          // every node needs an ID
          const id = this.generateId();

          //RC: Obtenemos las opciones ocupadas
          let currentOptions = []
          this.nodes.forEach((node) => {
            if(node.parentId == event.node.parentId) {
              currentOptions.push(node.parentOption)
            }
          })

          let defaultOptions = []
          let options = {}

          let indexTo = this.nodes.findIndex(x => x.id == destinationNode.parentId)

          if(indexTo > -1) {
            let to = this.nodes[indexTo]

            if(to.data.type == 'Announcement') {
              //RC: Seteamos las opciones disponibles
              defaultOptions = [' ']

              defaultOptions.forEach((i) => {
                if(currentOptions.findIndex(n => n == i) == -1) {
                  this.$set(options, i, i)
                }
              })

            } else if(to.data.type == 'TimeCondition') {
              //RC: Seteamos las opciones disponibles
              defaultOptions = ['No', 'Si']

              defaultOptions.forEach((i) => {
                if(currentOptions.findIndex(n => n == i) == -1) {
                  this.$set(options, i, i)
                }
              })
            } else if(to.data.type == 'Queue') {
              //RC: Seteamos las opciones disponibles
              defaultOptions = ['Timeout']

              defaultOptions.forEach((i) => {
                if(currentOptions.findIndex(n => n == i) == -1) {
                  this.$set(options, i, i)
                }
              })
            } else if(to.data.type == 'RingGroup') {
              //RC: Seteamos las opciones disponibles
              defaultOptions = ['Timeout']

              defaultOptions.forEach((i) => {
                if(currentOptions.findIndex(n => n == i) == -1) {
                  this.$set(options, i, i)
                }
              })

            } else if(to.data.type == 'IVR') {
              
              //RC: Seteamos las opciones disponibles
              defaultOptions = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Error', 'Timeout']

              defaultOptions.forEach((i) => {
                if(currentOptions.findIndex(n => n == i) == -1) {
                  this.$set(options, i, i)
                }
              })

            } else {
              // add to array of nodes
              this.nodes.push({
                id,
                ...destinationNode,
              });
            }

            //RC: Si tenemos opciones mostramos las opciones, sino mostramos la alerta
            if(currentOptions.length < defaultOptions.length) {
              if(defaultOptions.length > 1) {
                this.$swal.fire({
                  title: 'Seleccione una opción del menú para guardar',
                  icon: 'info',
                  input: 'select',
                  inputLabel: 'Seleccione una opción',
                  inputOptions: options,
                  showCancelButton: true
                }).then((result) => {
                  
                  if(result.isConfirmed) {
                    // add to array of nodes
                    this.$set(destinationNode, 'parentOption', result.value)
                    this.nodes.push({
                      id,
                      ...destinationNode,
                    });
                  }
                });
              } else {
                this.$set(destinationNode, 'parentOption', defaultOptions[0])
                this.nodes.push({
                      id,
                      ...destinationNode,
                    });
              }
            } else {
              this.$swal.fire({
                title: 'No hay opciones disponibles. Primero debe borrar una de las opciones.',
                icon: 'danger',
                showCancelButton: false
              })
            }
          }
        }
      },
      onDragStart (event) {
        console.log('onDragStart', event);
        this.dragging = true;
      },
    }
  }
</script>
<style lang="scss">
  .lateral-menu {
   padding-top: 10px;
  }
  .lateral-menu .lateral-menu-header {
    height: 80px;
  }
  .lateral-menu .lateral-menu-body {
    height: calc(100vh - 150px);
  }
  .flowy {
    padding-top: 10px;
    height: calc(100vh - 60px);
  }
  .lateral-menu .mr-24px {
    margin-right: 0px !important; 
  }
  .zoom100 > .flowy > .flowy-tree {
    zoom: 100%;
  }
  .zoom90 > .flowy > .flowy-tree {
    zoom: 95%;
  }
  .zoom80 > .flowy > .flowy-tree {
    zoom: 90%;
  }
  .zoom70 > .flowy > .flowy-tree {
    zoom: 85%;
  }
  .zoom60 > .flowy > .flowy-tree {
    zoom: 80%;
  }
  .zoom50 > .flowy > .flowy-tree {
    zoom: 75%;
  }
  .zoom40 > .flowy > .flowy-tree {
    zoom: 70%;
  }
  .zoom30 > .flowy > .flowy-tree {
    zoom: 65%;
  }
  .zoom20 > .flowy > .flowy-tree {
    zoom: 60%;
  }
  .zoom10 > .flowy > .flowy-tree {
    zoom: 55%;
  }
  .zoom0 > .flowy > .flowy-tree {
    zoom: 50%;
  }
  .cont-zoom {
    position: absolute;
    z-index: 10000;
    top: 70px;
    right: 40px;
    width: 200px;
  }
</style>