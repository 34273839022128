import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './locals/en_EN.json'
import es from './locals/es_ES.json'
import it from './locals/it_IT.json'

Vue.use(VueI18n)

export default new VueI18n({
  locale: 'es',
  messages: {
    en: {
      lang: en
    },
    es: {
      lang: es
    },
    it: {
      lang: it
    }
  }
})