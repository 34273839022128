<template lang="pug">
  #taskHeader
    template(v-if="startedTasks.length == 0")
      a(href="#" data-bs-toggle='offcanvas' data-bs-target='#offcanvasRight' aria-controls='offcanvasRight' @click="openTasks()")
        font-awesome-icon(:icon="['fas', 'stopwatch']")
    template(v-else)
      a(data-bs-toggle='offcanvas' href='#offcanvasHeaderTask' role='button' aria-controls='offcanvasHeaderTask')
        font-awesome-icon(:icon="['fas', 'stopwatch']")
        span.ps-2 {{intervalTasks.length}}
    #offcanvasHeaderTask.offcanvas.offcanvas-end(tabindex='-1' aria-labelledby='offcanvasHeaderTaskLabel')
      .offcanvas-header
        h5#offcanvasHeaderTaskLabel.offcanvas-title Mis tareas activas
        a(type='button' data-bs-dismiss='offcanvas' aria-label='Close')
          font-awesome-icon(icon='times')
      .offcanvas-body
        template(v-for="taskHeader in intervalTasks")
          .card.mb-2
            .card-body.p-2
              strong.text-dark {{taskHeader.name}}
              br
              button.btn.btn-danger.btn-round.mt-2(@click="endTask(taskHeader)")
                font-awesome-icon.me-2(icon="stop") 
                span(:key="'task' + taskHeader.id + counterKey") {{taskHeader.start | diffToNow | timeInHours}}

</template>
<script>
export default {
  name: 'TaskHeader',
  data() {
    return {
      counterKey: 0,
      counterKeyInterval: null,
    }
  },
  watch: {
    intervalTasks(newVal) {
      if(newVal.length == 0) {
        if(this.counterKeyInterval) {
          clearInterval(this.counterKeyInterval)
          this.counterKey = 0
        }
      } else {
        if(!this.counterKeyInterval) {
          setInterval(()=> {
            this.counterKey = this.counterKey + 1;
          }, 1000)
        }
      }
    }
  },
  computed: {
    currentUser() {
      if(localStorage.getItem('user')) {
        let user = JSON.parse(localStorage.getItem('user'))
        return user.user
      } else {
        return {}
      }
    },
    startedTasks() {
      if(this.$store.state.taskModule.startedTasks) {
        return this.$store.state.taskModule.startedTasks
      } else {
        return []
      }
    },
    intervalTasks() {
      let intervalTasks = []
      this.startedTasks.forEach((task) => {
        task.task_times.forEach((taskTime) => {
          if(taskTime.user_id == this.currentUser.id && !taskTime.duration) {
            intervalTasks.push({
              id: task.id,
              name: task.task_description.name,
              start: taskTime.start
            })
          }
        })
      })

      return intervalTasks
    },
    totalDuration() {
      let duration = 0;

      this.intervalTasks.forEach((task) => {
        duration = duration + this.$options.filters.diffToNow(task.start)
      })

      return duration;
    }
  },
  beforeCreate() {
    this.$store.dispatch("getMyCurrentTasks")
  },
  beforeDestroy() {
      if(this.counterKeyInterval) {
        clearInterval(this.counterKeyInterval)
        this.counterKey = 0
      }
    },
  methods: {
    endTask(task) {
      this.$store.dispatch("endTask", {
        taskId: task.id
      })
    },
    openTasks() {
      this.$router.push('/tasks/list')
    }
  }
}
</script>
<style scoped>
  
</style>
