import authService from '../services/api/auth'

const authModule = {
    state: {
        user: {},
        backendUrl: '',
    },
    mutations: {
        setUser  (state, user) {
            state.user = user
        },
        setBackendUrl  (state, backendUrl) {
            state.backendUrl = backendUrl
        }
    },
    actions: {
        login (context, payload) {
            return new Promise((resolve, reject) => {
                authService.login(payload.username, payload.password)
                    .then(user => {
                        context.commit("setUser", user.user)
                        resolve(user)
                    })
                    .catch(reject)
            })
        },
        logout (context) {
            return new Promise((resolve, reject) => {
                authService.logout()
                    .then(user => {
                        context.commit("setUser", {})
                        resolve(user)
                    })
                    .catch(reject)
            })
        },
        changeCompany (context, payload) {
            return new Promise((resolve, reject) => {
                authService.changeCompany(payload.companyId)
                    .then(user => {
                        context.commit("setUser", user)
                        resolve(user)
                    })
                    .catch(reject)
            })
        },
        changeExtension (context, payload) {
            return new Promise((resolve, reject) => {
                authService.changeExtension(payload.username, payload.password, payload.extensionId)
                    .then(user => {
                        context.commit("setUser", user)
                        resolve(user)
                    })
                    .catch(reject)
            })
        },
    }
}

export default authModule