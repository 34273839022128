<template lang="pug">
  .gc-user
    a#offcanvasUsersBtn(href="#" data-bs-toggle='offcanvas' data-bs-target='#offcanvasUsers' aria-controls='offcanvasUsers')
      font-awesome-icon(:icon="['fas', 'users']")
    #offcanvasUsers.offcanvas.offcanvas-end(tabindex='-1' aria-labelledby='offcanvasUsersLabel')
      .offcanvas-header
        h5#offcanvasUsersLabel.offcanvas-title Usuarios
        a(type='button' data-bs-dismiss='offcanvas' aria-label='Close')
          font-awesome-icon(icon='times')
      .offcanvas-body
        .alert.bg-warning-opacity-25(role="alert" v-if="channelInTransfer")
          .d-flex
            span Transferencia en curso, pulse la cruz para recuperar la llamada
            button.btn-close.btn-close-dark.me-2.m-auto(type="button" aria-label="Close" @click="cancelAttendedTransfer()")
        .alert.bg-primary-opacity-25(role="alert" v-if="channelToTransfer")
          .d-flex
            span Para transferir la llamada pulse sobre el usuario deseado
            button.btn-close.btn-close-dark.me-2.m-auto(type="button" aria-label="Close" @click="setChannelToTransfer(null)")
        .alert.bg-primary-opacity-25(role="alert" v-if="channelToRedirect")
          .d-flex
            span Para redirigir la llamada pulse sobre el usuario deseado
            button.btn-close.btn-close-dark.me-2.m-auto(type="button" aria-label="Close" @click="setChannelToRedirect(null)")
        .row
          .col.m-1
            .input-group
              button.btn.btn-secondary
                font-awesome-icon(icon="filter")
              input.form-control(type="text" placeholder="Buscar usuario" v-model="filter")
        .row
          .col
            user-status(
              v-for="user in filteredUsers" :key="'gc-user-user' + user.id"
              :user="user" 
              :calls="userCalls[user.id] ? userCalls[user.id].calls : []" 
              :showCalls="false" 
            )
</template>
<script>
  import UserStatus from './UserStatus.vue'
  export default {
    name: 'GcUser',
    components: {
      UserStatus
    },
    data () {
      return {
        filter: '',
      }
    },
    computed: {
      users() {
        return this.$store.state.userModule.users
      },
      filteredUsers() {
        return this.users.filter((u) => {
          return (u.name.toLowerCase().includes(this.filter) || (u.extension && u.extension.number.includes(this.filter)));
        })
      },
      currentUser() {
        return this.$store.state.currentUser
      },
      calls() {
        return this.$store.state.callModule.calls
      },
      channelToRedirect() {
        return this.$store.state.callModule.channelToRedirect
      },
      channelToTransfer() {
        return this.$store.state.callModule.channelToTransfer
      },
      channelInTransfer() {
        return this.$store.state.callModule.channelInTransfer
      },
      userCalls() {
        let userCalls = {}

        this.calls.forEach((call) => {
          call.connected.forEach((connected) => {
            let index = this.users.findIndex((u) => u.extension && u.extension.number == connected)

            if(index > -1) {
              if(!userCalls[this.users[index].id]) {
                userCalls[this.users[index].id] = {}
                this.$set(userCalls[this.users[index].id], 'calls', [])
              }

              userCalls[this.users[index].id].calls.push(call)
            }
          })
        })

        return userCalls
      }
    },
    mounted() {
      window.Echo.channel('App.Company.' + this.currentUser.company_id)
        .listen('UserLogin', (e) => {
            this.processUserLogin(e.userSession)
        })
        .listen('UserLogout', (e) => {
            this.processUserLogout(e.user)
        })
        .listen('UserUpdate', (e) => {
            this.processUserUpdate(e.user)
        })

      this.$store.dispatch("getRegisters", {moduleUrl: "users"})
        .then((users) => {
          this.$store.commit("setUsers", users)
        })
        
      this.$store.dispatch("setCurrentUser")
    },
    methods: {
      processUserLogin(userSession) {
        let index = this.users.findIndex((u) => u.id == userSession.user_id)

        if(index > -1) {
          let user = this.users[index]

          this.$set(user, 'active_user_session', userSession)
          this.$store.commit("updateUser", user)
        }
      },
      processUserLogout(user) {
        let index = this.users.findIndex((u) => u.id == user.id)

        if(index > -1) {
          let user = this.users[index]

          this.$set(user, 'active_user_session', null)
          this.$store.commit("updateUser", user)
        }
      },
      processUserUpdate(user) {
        let index = this.users.findIndex((u) => u.id == user.id)

        if(index > -1) {
          this.$store.commit("updateUser", user)
        }
      },
      cancelAttendedTransfer() {
        this.$store.dispatch("cancelAttendedtransferChannel", {
          channel: this.channelInTransfer,
        })
      },
      setChannelToTransfer() {
        this.$store.commit("setChannelToTransfer", '')
      },
      setChannelToRedirect() {
        this.$store.commit("setChannelToRedirect", '')
      }
    }
  }
</script>
<style lang="scss">
</style>