<template lang="pug">
  .gc-rest-time
    .template(v-if="!userRestTime")
      a(href="#" data-bs-toggle='offcanvas' data-bs-target='#offcanvasRestTime' aria-controls='offcanvasRestTime')
        font-awesome-icon(:icon="['fas', 'coffee']")
    template(v-else)
      a.position-relative(data-bs-toggle='offcanvas' href='#offcanvasRestTime' role='button' aria-controls='offcanvasRestTime')
        font-awesome-icon(:icon="['fas', 'coffee']")
        span.position-absolute.top-0.start-100.translate-middle.p-1.bg-danger.border.border-light.rounded-circle.blink-slow
          span.visually-hidden {{userRestTime.rest_time.name}}
    button.alert-notification.p-2.text-center(v-if="userRestTime" @click="endUserRestTime()")
      span.pe-2 {{userRestTime.rest_time.name}}
      font-awesome-icon(:icon="['fas', 'coffee']")
      span.ps-2 00:00:00
    #offcanvasRestTime.offcanvas.offcanvas-end(tabindex='-1' aria-labelledby='offcanvasRestTimeLabel')
      .offcanvas-header
        h5#offcanvasRestTimeLabel.offcanvas-title Descansos
        a(type='button' data-bs-dismiss='offcanvas' aria-label='Close')
          font-awesome-icon(icon='times')
      .offcanvas-body
        .input-select.mb-2
          v-select(:options="restTimes" :reduce="label => label.id"  label="label" v-model="restTimeId" placeholder="Seleccione un descanso")
        button.btn.btn-primary(v-if="!userRestTime" @click="startUserRestTime()") 
          span.icon.me-2
            font-awesome-icon(icon="play")
          span Iniciar descanso
        button.btn.btn-danger(v-if="userRestTime" @click="endUserRestTime()") 
          span.icon.me-2
            font-awesome-icon(icon="stop")
          span Finalizar descanso
        
</template>
<script>

  export default {
    name: 'GcRestTime',
    data () {
      return {
        restTimes: [],
        restTimeId: null
      }
    },
    computed: {
      users() {
        return this.$store.state.userModule.users
      },
      userRestTime() {
        return this.$store.state.restTimeModule.userRestTime
      },
      currentUser() {
        return this.$store.state.currentUser
      }
    },
    mounted() {
      if(this.currentUser.id) {
        window.Echo.channel('App.Company.' + this.currentUser.company_id)
          .listen('UserRestTimeStart', (e) => {
              this.processUserRestTimeStart(e.userRestTime)
          })
          .listen('UserRestTimeEnd', (e) => {
              this.processUserRestTimeEnd(e.user)
          })
      }

      this.$store.dispatch("getRegistersList", {
        moduleUrl: 'rest_times',
        columns: 'id,name',
        conditions: ''
      }).then((restTimes) => {
        this.restTimes = restTimes
      })
    },
    methods: {
      processUserRestTimeStart(userRestTime) {
        let index = this.users.findIndex((u) => u.id == userRestTime.user_id)

        if(index > -1) {
          let user = this.users[index]

          this.$set(user, 'active_rest_time', userRestTime)
          this.$store.commit("updateUser", user)
        }
      },
      processUserRestTimeEnd(user) {
        let index = this.users.findIndex((u) => u.id == user.user_id)

        if(index > -1) {
          let user = this.users[index]

          this.$set(user, 'active_rest_time', null)
          this.$store.commit("updateUser", user)
        }
      },
      startUserRestTime() {
        if(this.restTimeId) {
          this.$store.dispatch("startUserRestTime", {restTimeId: this.restTimeId})
        }
      },
      endUserRestTime() {
        if(this.userRestTime) {
          this.$store.dispatch("endUserRestTime")
        }
      },
    }
  }
</script>
<style lang="scss">
  @import "../../scss/colors.scss";

  .alert-notification {
    position: fixed;
    top: 10px;
    width: 40%;
    left: 20%;
    background: $danger;
    color: #ffffff;
    border:0px solid transparent;
    border-radius: 8px;
  }
</style>