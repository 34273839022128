<template lang="pug">
  .my-call-item
    .toast.d-block(role='alert') 
      .toast-header(:class="isRinging ? 'blink-slow ' + getCallBackground() : getCallBackground() ")
        strong.me-auto(v-if="call.callType == 'in' || (user && call.callType == 'internal' && call.Exten == currentUser.extension.number)") {{call.CallerIDNum}}
        strong.me-auto(v-else-if="call.callType == 'out' || (user && call.callType == 'internal' && call.CallerIDNum == user.extension.number)") {{call.Exten}}
        strong.me-auto(v-else) {{call.CallerIDNum}} {{call.Exten}}
        small(v-if="myCallState == 'Activa'" :key="'callStart-' + (user ? user.id : '')  + '-' + call.Linkedids[0] + '-' + counterKey") {{[call.start, "unix"] | unixToDateTimeEn | diffToNow | timeInHoursMin}}
        button.btn-close(type='button' data-bs-dismiss='toast' aria-label='Close' @click="closeMyCall()")
      .toast-body
        .row
          .col
            span.me-auto(v-if="call.callType == 'in' || (user && call.callType == 'internal' && call.Exten == currentUser.extension.number)") {{call.CallerIDNum}}
            span.me-auto(v-else-if="call.callType == 'out' || (user && call.callType == 'internal' && call.CallerIDNum == user.extension.number)") {{call.Exten}}
            span.me-auto(v-else) {{call.CallerIDNum}} {{call.Exten}}
        .row
          .col
            select.form-select
              option(value="-1") Sin final
              option(value="1") final 1
              option(value="2") final 2
              option(value="3") final 3
        .row.g-0(v-if="hover")
        .col.small.pt-0.pb-0
          .d-flex.justify-content-around
            div
              button.btn.btn-link.p-0.text-muted(title="Indicar Final de la llamada") 
                font-awesome-icon(:icon="['fas', 'flag-checkered']")
            div
              button.btn.btn-link.p-0.text-muted(title="Añadir un comentario") 
                font-awesome-icon(:icon="['fas', 'comments']")
            div(v-if="myCallState == 'Activa' || isRinging")
              button.btn.btn-link.p-0.text-dark(@click="parkMainChannel()" title="Aparcar llamada") 
                font-awesome-icon(:icon="['fas', 'parking']")
            div(v-else)
              button.btn.btn-link.p-0.text-muted(type="button" title="Aparcar llamada") 
                font-awesome-icon(:icon="['fas', 'parking']")
            div(v-if="myCallState == 'Activa' || isRinging")
              button.btn.btn-link.p-0.text-dark(@click="attendedTransferOwnChannel()" title="Transferencia atendida")
                font-awesome-icon(:icon="['fas', 'exchange-alt']")
            div(v-else)
              button.btn.btn-link.p-0.text-muted(type="button" title="Transferencia atendida")
                font-awesome-icon(:icon="['fas', 'exchange-alt']")
            div(v-if="myCallState == 'Activa' || isRinging")
              button.btn.btn-link.p-0.text-dark(@click="redirectMainChannel()" title="Transferencia ciega")
                font-awesome-icon(:icon="['fas', 'retweet']")
            div(v-else)
              button.btn.btn-link.p-0.text-muted(title="Transferencia ciega")
                font-awesome-icon(:icon="['fas', 'retweet']")
            div(v-if="myCallState == 'Activa' || isRinging")
              button.btn.btn-link.p-0.text-dark(@click="hangupOwnChannel()" title="Colgar llamada")
                font-awesome-icon(:icon="['fas', 'phone-slash']")
            div(v-else)
              button.btn.btn-link.p-0.text-muted(type="button" title="Colgar llamada")
                font-awesome-icon(:icon="['fas', 'phone-slash']")
</template>

<script>
  import {Offcanvas} from 'bootstrap'
  export default {
    name: 'MyCallItem',
    props: { 
      call: Object,
      index: Number
    },
    data () {
      return {
        hover: false,
        trunks: ['T1a', 'T1b', 'T2A', 'T2B', 'T4a', 'T4b'],
        isInTransfer: false,
        isRinging: true
      }
    },
    computed: {
      counterKey() {
        return this.$store.state.counterKey
      },
      currentUser() {
        return this.$store.state.currentUser
      },
      channelToRedirect() {
        return this.$store.state.callModule.channelToRedirect
      },
      channelToTransfer() {
        return this.$store.state.callModule.channelToTransfer
      },
      channelInTransfer() {
        return this.$store.state.callModule.channelInTransfer
      },
      callState() {
        if(this.call.channels.every((c) => c.ChannelStateDesc == 'Up')) {
          return 'Activa'
        } else if(this.call.hold) {
          return 'Retenida'
        } else {
          return 'Inactiva'
        }
      },
      myChannels() {
        let channels = []
        this.call.channels.forEach((channel) => {
          if(channel.name == this.currentUser.extension.number) {
            channels.push(channel)
          }
        })

        return channels
      },
      myCallState() {
        if(this.myChannels.length == 0) {
          return 'Inactiva'
        } else if(this.call.hold) {
          return 'Retenida'
        } else if(this.myChannels.every((c) => c.ChannelStateDesc == 'Up')) {
          return 'Activa'
        } else {
          return 'Inactiva'
        }
      }
    },
    watch: {
      myCallState(newVal) {
        if(newVal == 'Activa') {
          this.isRinging = false
        }
      },
      channelInTransfer(newVal, oldVal) {
        if(newVal != oldVal) {
          if(this.call.channels.some((c) => c.channel == newVal)) {
            this.isInTransfer = true
          }
        }
      }
    },
    beforeMount() {
      
    },
    methods: {
      getCallBackground() {
        let background = ''

        if(this.myCallState == 'Activa') {
          background = 'bg-success-opacity-25'
        } else if(this.myCallState == 'Retenida') {
          background = 'bg-warning-opacity-25'
        } else if(this.myCallState == 'Inactiva' && this.isRinging) {
          background = 'bg-primary-opacity-25'
        }

        return background
      },
      getMainChannel() {
        let getMainChannel = ''

        this.call.channels.forEach((channel) => {
          let indexSlash = channel.channel.indexOf('/')
          let indexDash = channel.channel.indexOf('-')

          // let channelType = channel.channel.substring(0, indexSlash)
          let channelName = channel.channel.substring(indexSlash + 1, indexDash)
          // let channelUnique = channel.channel.substring(indexDash + 1)

          let indexTrunk = this.trunks.findIndex(x => x == channelName)

          if(indexTrunk > -1) {
            getMainChannel = channel.channel
          }
        })

        return getMainChannel
      },
      getOwnChannel() {
        let getOwnChannel = ''

        this.call.channels.forEach((channel) => {
          let indexSlash = channel.channel.indexOf('/')
          let indexDash = channel.channel.indexOf('-')

          // let channelType = channel.channel.substring(0, indexSlash)
          let channelName = channel.channel.substring(indexSlash + 1, indexDash)
          // let channelUnique = channel.channel.substring(indexDash + 1)

          if(channelName == this.currentUser.extension.number) {
            getOwnChannel = channel.channel
          }
        })

        return getOwnChannel
      },
      closeMyCall() {
        if(this.myCallState != 'Activa') {
          this.$store.commit("deleteMyCallByIndex", this.index)
        }
      },
      hangupOwnChannel() {
        let ownChannel = this.getOwnChannel()

        if(ownChannel) {
          this.isRinging = false
          this.$store.dispatch("hangupChannel", ownChannel)
        }
      },
      parkMainChannel() {
        let mainChannel = this.getMainChannel()

        if(mainChannel) {
          this.isRinging = false
          this.$store.dispatch("parkChannel", mainChannel)
        }
      },
      redirectMainChannel() {
        let mainChannel = this.getMainChannel()

        if(!this.channelToRedirect) {
          if(mainChannel) {
            this.$store.commit("setChannelToRedirect", mainChannel)

            let offcanvasUsers = document.getElementById('offcanvasUsers')
            if(offcanvasUsers.style.visibility != 'visible') {
              new Offcanvas(offcanvasUsers).show()
            }
          }
        } else if(mainChannel == this.channelToRedirect) {
          this.$store.commit("setChannelToRedirect", '')
        }
      },
      attendedTransferOwnChannel() {
        let mainChannel = this.getOwnChannel()

        if(!this.channelToTransfer) {
          if(mainChannel) {
            this.$store.commit("setChannelToTransfer", mainChannel)
            this.isInTransfer = true

            let offcanvasUsers = document.getElementById('offcanvasUsers')
            if(offcanvasUsers.style.visibility != 'visible') {
              new Offcanvas(offcanvasUsers).show()
            }
          }
        } else if(mainChannel == this.channelToTransfer) {
          this.$store.commit("setChannelToTransfer", '')
          this.isInTransfer = false
        }
      }
    }
  }
</script>
<style lang="scss">
</style>