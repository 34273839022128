<template lang="pug">
  .invoice-account
    .header-actions
      .header-actions-content
        .d-flex
          .flex-grow-1.p-2
            .btn-group.me-2.ms-2
              
            //.btn-group.me-2.ms-2
              button.btn.btn-outline-secondary
                font-awesome-icon.me-2(:icon="['fas', 'euro-sign']")
                span Modificar pagos
          .p-2
            .btn-group.me-2
              a.btn.btn-outline-secondary(type='button' href="/invoices")
                font-awesome-icon.me-2(:icon="['fas', 'arrow-left']")
                span Volver
    .main-content
      .p-2
        .container-fluid
          .row
            .col-12
              .card.mb-2
                .card-body
                  h5.card-title Seleccionar la cuenta
                  .row
                    .col-4
                    .col-4
                      label Seleccione la cuenta a facturar
                      v-select(:options="accountOptions" :reduce="account => account.id"  label="label" @search="accountsAjaxSearch" placeholder="Cliente" v-model="accountId")
              .card.mb-2(v-if="accountId")
                .card-body
                  h5.card-title Seleccionar elementos
                  table.table
                    thead
                      tr
                        th
                        th Número
                        th Estado
                        th Producto
                        th.text-end Unidades
                        th.text-end Precio/u
                        th.text-end Descuento
                        th.text-end Impuestos
                    tbody
                      tr(v-for="deliveryNoteLine in deliveryNoteLines")
                        td
                          input.checkbox(type="checkbox" v-model="selectedDeliveryNoteLines" :value="deliveryNoteLine.id")
                        td {{deliveryNoteLine.legal_document.fullnumber}}
                        td {{deliveryNoteLine.legal_document.legal_document_status.name}}
                        td {{deliveryNoteLine.product.name}}
                        td.text-end {{ (deliveryNoteLine.quantity - deliveryNoteLine.invoiced_quantity)}}
                        td.text-end {{deliveryNoteLine.price}}
                        td.text-end {{deliveryNoteLine.discount}}
                        td.text-end 
                          span(v-for="tax in deliveryNoteLine.taxes") {{tax.name}}
              .card.mb-2(v-if="accountId")
                .card-body
                  h5.card-title Definir la cabecera
                  draw-simp-form(
                  :module="invoiceModule" 
                  :value="invoiceData"
                  @createRegister="createInvoice"
                )

</template>
<script>
  import DrawSimpForm from '../../components/forms/DrawSimp.vue'
  export default {
    name: 'InvoiceAccount',
    components: {
      DrawSimpForm,
    },
    beforeMount() {
      this.$store.dispatch("getModuleByUrl", {moduleUrl: 'invoices'})
        .then((res) => {
          this.invoiceModule = res
        })
    },
    data () {
      return {
        accountId: null,
        accountOptions: [],
        invoiceModule: {},
        invoiceData: {},
        deliveryNoteLines: [],
        selectedDeliveryNoteLines: [],
      }
    },
    watch: {
      accountId(newVal) {
        this.$set(this.invoiceData, 'account_id', newVal)
        this.getUninvoicedElements(newVal)
      }
    },
    methods: {
      accountsAjaxSearch(search, loading) {
        let filter = {
              filter: search,
              moduleUrl: 'accounts',
              columns: 'id,name',
          }
          loading(true)
          this.$store.dispatch('getRegistersListAjax', filter).then((res) => {
              this.accountOptions = res
              loading(false)
          })
      },
      createInvoice(invoice) {
        //RC: Una vez generada la factura tenemos las lineas
        console.log(invoice)
        this.$store.dispatch("legalDocumentAddMultipleLines", {
          legalDocumentId: invoice.id,
          data: {
            legal_document_lines_id: this.selectedDeliveryNoteLines
          }
        }).then(() => {
          this.$router.push('/invoices/' + invoice.id)
        })

      },
      getUninvoicedElements(accountId) {
        this.$store.dispatch("getAccountUninvoicedElements", {accountId: accountId})
          .then((res) => {
            this.deliveryNoteLines = res.delivery_note_lines
          })
      }
    }
  }
</script>
<style scooped lang="scss">
</style>