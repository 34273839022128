<template lang="pug">
  .login
    .row
      .col-md-4
      .col-md-4
        .card
          .card-body(v-if="!user.id")
            form(name='form' @submit.prevent='login')
              .form-group
                label(for='username') Usuario
                input.form-control(v-model='username' type='text' name='username')
              .form-group
                label(for='password') Password
                input.form-control(v-model='password' type='password' name='password')
              hr
              .d-grid.gap-2
                button.btn.btn-primary(:disabled='loading')
                  span.spinner-border.spinner-border-sm(v-show='loading')
                  span Login
              .form-group
                .alert.alert-danger(v-if='message' role='alert') {{message}}
          .card-body(v-else)
            form(name='form' @submit.prevent='setExtension')
              .form-group
                label(for='extension_id') Seleccione una extensión
                v-select(:options="extensions" :reduce="label => label.id"  label="label" v-model="extensionId" placeholder="Seleccione una extensión")
              hr
              .d-grid.gap-2
                button.btn.btn-primary(:disabled='loading')
                  span.spinner-border.spinner-border-sm(v-show='loading')
                  span Login

</template>

<script>

export default {
  name: 'Login',
  data () {
    return {
      loading: false,
      message: '',
      username: '',
      password: '',
      user: {},
      extensions: [],
      extensionId: null
    }
  },
  beforeCreate() {
    if(localStorage.getItem('user')) {
      this.user = JSON.parse(localStorage.getItem('user'))
      this.$router.push('/users');
    }
  },
  methods: {
    login() {
      
      if (this.username && this.password) {
        this.$store.dispatch('login', {
          username: this.username,
          password: this.password
        }).then(
            (res) => {
              if (res.access_token) {
                this.user = res.user

                if(this.user.extensions.length > 1) {
                  this.extensions = []
                  this.user.extensions.forEach((ext) => {
                    this.extensions.push({
                      id: ext.id,
                      label: ext.name + ' - ' + ext.number
                    })
                  })
                } else {
                  location.reload()
                }
              }
            },
            error => {
              this.message =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
            }
          );
      }
    },
    setExtension() {
      if(this.user.id && this.extensionId) {
        this.$store.dispatch("changeExtension", { 
          username: this.username,
          password: this.password,
          extensionId: this.extensionId
        })
        .then(() => {
          location.reload()
        })
      }
    }
  }

}
</script>

<style lang="scss">
  
</style>
