import appService from "./appService";

const restTimeService = {
    startUserRestTime(restTimeId) {
        return appService.get('/user_rest_times/start/' + restTimeId)
            .then((res) => res.data)
    },
    endUserRestTime() {
        return appService.get('/user_rest_times/end')
            .then((res) => res.data)
    },
}

export default restTimeService;