import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';
import 'bootstrap';
import CKEditor from 'ckeditor4-vue';
import vSelect from 'vue-select'
import VueSweetalert2 from 'vue-sweetalert2';
import moment from "vue-moment";
import i18n from './lang'
import FlowyPlugin from "@hipsjs/flowy-vue";
import VueDragscroll from 'vue-dragscroll'
import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';
import VueCustomTooltip from '@adamdehaven/vue-custom-tooltip'
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faBars,
  faAngleDown,
  faAngleUp,
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faBell,
  faEnvelope,
  faStopwatch,
  faEdit,
  faTrash,
  faEllipsisV,
  faPhone,
  faCoffee,
  faEye,
  faPlusCircle,
  faUsers,
  faIdCard,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faTimes,
  faBriefcase,
  faTruckLoading,
  faTasks,
  faUserCog,
  faUserShield,
  faFileInvoice,
  faInbox,
  faArrowRight,
  faArrowLeft,
  faSave,
  faEuroSign,
  faBan,
  faPlay,
  faStop,
  faCalendar,
  faPaperclip,
  faTrafficLight,
  faFlag,
  faFlagCheckered,
  faLayerGroup,
  faColumns,
  faCogs,
  faPaste,
  faUpload,
  faUnlink,
  faCircle,
  faClock,
  faSitemap,
  faListOl,
  faVolumeUp,
  faFilePdf,
  faServer,
  faChartLine,
  faComments,
  faFilter,
  faRedo,
  faParking,
  faRetweet,
  faPhoneSlash,
  faHeadphonesAlt,
  faHeadset,
  faUndo,
  faExchangeAlt,
  faCopy,
  faMoon,
  faSun, 
} from '@fortawesome/free-solid-svg-icons';

library.add(faHome, faUser, faUserPlus, faSignInAlt, faSignOutAlt, faBars, faAngleUp, faAngleDown, faBell, faEnvelope, faStopwatch, faEdit, faTrash, faEllipsisV, faPhone, faCoffee, faEye, faPlusCircle, faUsers, faIdCard, faAngleDoubleLeft, faAngleDoubleRight, faTimes, faBriefcase, faTruckLoading, faTasks, faUserCog, faUserShield, faFileInvoice, faInbox, faArrowRight, faArrowLeft, faSave, faEuroSign, faBan, faPlay, faStop, faCalendar, faPaperclip, faTrafficLight, faFlag, faFlagCheckered, faLayerGroup, faColumns, faCogs, faPaste, faUpload, faUnlink, faCircle, faClock, faSitemap, faListOl, faVolumeUp, faFilePdf, faServer, faChartLine, faComments, faFilter, faRedo, faParking, faRetweet, faPhoneSlash, faHeadphonesAlt, faHeadset, faUndo, faExchangeAlt, faCopy, faMoon, faSun);

import 'sweetalert2/dist/sweetalert2.min.css';
import "@hipsjs/flowy-vue/dist/lib/flowy-vue.css";

import Echo from 'laravel-echo';
import { np4Config } from './config.js'
window.Pusher = require('pusher-js');

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: 'np4key',
  wsHost: np4Config.wsHost,
  wsPort: np4Config.wsPort,
  wssPort: np4Config.wssPort,
  disableStats: true,
  encrypted: true,
  enabledTransports: ['ws', 'wss'],
  disabledTransports: ['sockjs', 'xhr_polling', 'xhr_streaming']
});

Vue.config.productionTip = false

Vue.use( CKEditor );
Vue.use(VueSweetalert2);
Vue.use(moment)
Vue.use(FlowyPlugin);
Vue.use(VueDragscroll)
Vue.use(VueCustomTooltip)

import DemoNode from "./components/pbx/DemoNode.vue"
import DemoBlock from "./components/pbx/DemoBlock.vue"
import FilterDepartmentNode from "./components/departments/FilterDepartmentNode.vue"

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('v-select', vSelect);
Vue.component('demo-node', DemoNode);
Vue.component('demo-block', DemoBlock);
Vue.component('filter-department-node', FilterDepartmentNode);


numeral.register('locale', 'es', {
  delimiters: {
      thousands: '.',
      decimal: ','
  },
  abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't'
  },
  ordinal : function (number) {
      return number === 1 ? 'er' : 'ème';
  },
  currency: {
      symbol: '€'
  }
});

//numeral.locale('es');

Vue.filter('numFormat', numFormat(numeral));

Vue.filter('timeInHours', function (value) {
  if (value) {
      let hours = parseInt(Math.floor(value / 3600));
      let minutes = parseInt(Math.floor((value - (hours * 3600)) / 60));
      let seconds = parseInt((value - ((hours * 3600) + (minutes * 60))) % 60);
      let dHours = (hours > 9 ? hours : '0' + hours);
      let dMins = (minutes > 9 ? minutes : '0' + minutes);
      let dSecs = (seconds > 9 ? seconds : '0' + seconds);
      return dHours + ":" + dMins + ":" + dSecs;
  } else {
      return '00:00:00';
  }
});

Vue.filter('formatDate', function (value) {
  if (value) {
      return Vue.moment(new Date(value)).format("DD-MM-YYYY")
  } else {
      return ''
  }
});
Vue.filter('formatFullTime', function (value) {
  if (value) {
      return Vue.moment(new Date(value)).format("DD-MM-YYYY, H:mm:ss")
  } else {
      return ''
  }
});
Vue.filter('formatTime', function (value) {
  if (value) {
      let parts = value.split(":")

      return parts[0] + ':' + parts[1]
  } else {
      return ''
  }
});

Vue.filter('diffToNow', function (value) {
  let start = new Date(value);
  let end = new Date();

  return (end.getTime() / 1000) - (start.getTime() / 1000)
});

Vue.filter('timeInHours', function (value) {
  if (value) {
      let hours = parseInt(Math.floor(value / 3600));
      let minutes = parseInt(Math.floor((value - (hours * 3600)) / 60));
      let seconds = parseInt((value - ((hours * 3600) + (minutes * 60))) % 60);
      let dHours = (hours > 9 ? hours : '0' + hours);
      let dMins = (minutes > 9 ? minutes : '0' + minutes);
      let dSecs = (seconds > 9 ? seconds : '0' + seconds);
      return dHours + ":" + dMins + ":" + dSecs;
  } else {
      return '00:00:00';
  }
});

Vue.filter('timeInHoursMin', function (value) {
  if (value) {
      let hours = parseInt(Math.floor(value / 3600));
      let minutes = parseInt(Math.floor((value - (hours * 3600)) / 60));
      let seconds = parseInt((value - ((hours * 3600) + (minutes * 60))) % 60);

      let str = ""

      if(hours > 0) {
        str = str + hours +"h "
      }

      if(minutes > 0) {
        str = str + minutes +"m "
      }

      if(seconds > 0) {
        str = str + seconds +"s"
      }

      return str;
  } else {
      return '0s';
  }
});

Vue.filter('unixToDateTime', function (value) {
  if (value) {
      return Vue.moment.unix(value[0]).format("DD/MM/YYYY HH:mm:ss")
  } else {
      return ''
  }
});

Vue.filter('unixToDateTimeEn', function (value) {
  if (value) {
      return Vue.moment.unix(value[0]).format("YYYY-MM-DD HH:mm:ss")
  } else {
      return ''
  }
});

Vue.filter('shortTileCapitalize', function(str) {
  let splitStr = str.toLowerCase().split(' ');
  let stringShot = '';
  
  for (var i = 0; i < splitStr.length; i++) {
    stringShot = stringShot + splitStr[i].charAt(0).toUpperCase();
  }
   
  return stringShot; 
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
