<template lang="pug">
  #header
    gc-call
    gc-extension
    #companyModal.modal.fade(tabindex='-1' aria-labelledby='companyModalLabel' aria-hidden='true')
      .modal-dialog
        .modal-content
          .modal-header
            h5#companyModalLabel.modal-title Seleccione una empresa
            button.btn-close(type='button' data-bs-dismiss='modal' aria-label='Close')
          .modal-body
            v-select(:options="companies" :reduce="label => label.id"  label="label" v-model="newCompanyId")
          .modal-footer
            button.btn.btn-primary(type='button' @click="changeCompany()") Seleccionar

    #offcanvasExample.offcanvas.offcanvas-start.main-menu(tabindex='-1' aria-labelledby='offcanvasExampleLabel')
      .offcanvas-header
        h5#offcanvasExampleLabel.offcanvas-title Men&uacute;
        a(type='button' data-bs-dismiss='offcanvas' aria-label='Close')
          font-awesome-icon(icon='times')
      .offcanvas-body
        .text-center.cont-logo
          // img.rounded(src='http://flexandlux.es/wp-content/uploads/2020/09/FelxLux_negro-2.png')
        ul.list-unstyled.ps-0
          li.mb-1(v-for="mainMenuItem in mainMenu")
            button.btn.btn-toggle.align-items-center.rounded.collapsed(data-bs-toggle='collapse' :data-bs-target="'#item' + mainMenuItem.id + '-collapse'" aria-expanded='true')
              font-awesome-icon(:icon='mainMenuItem.icon')
              span.ps-2 {{mainMenuItem.name}}
            .collapse(:id="'item' + mainMenuItem.id + '-collapse'")
              ul.btn-toggle-nav.list-unstyled.fw-normal.pb-1.small
                li(v-for="mainMenuSubitem in mainMenuItem.main_menu_items")
                  router-link(:to="mainMenuSubitem.url") {{mainMenuSubitem.name}}
    nav.header-nav
      .header-nav-content
        .d-flex
          div
            .d-flex.flex-row.header-nav-menu
              .p-2.header-nav-menu-item
                a(data-bs-toggle='offcanvas' href='#offcanvasExample' role='button' aria-controls='offcanvasExample')
                  font-awesome-icon(icon='bars')
              .p-2.header-nav-menu-item
                a(v-if="user && user.company_id" href='#' data-bs-toggle="modal" data-bs-target="#companyModal")
                  strong {{user.company.name}}
          .flex-grow-1
          div
            .d-flex.flex-row-reverse.header-nav-menu-right
              .pt-2.me-2
                a(href='/logout')
                  font-awesome-icon(icon="sign-out-alt")
              .p-2.ms-2
                span {{user.name}}&nbsp;
              .p-2.ms-2.me-2
                a(href='#' @click="toogleTheme()")
                  font-awesome-icon(v-if="darkTheme" :icon="['fas', 'sun']")
                  font-awesome-icon(v-else="darkTheme" :icon="['fas', 'moon']")
              .p-2.ms-2.me-2
                a(href='#')
                  font-awesome-icon(:icon="['fas', 'envelope']")
              .p-2.ms-2.me-2
                a(href='#')
                  font-awesome-icon(:icon="['fas', 'id-card']")
              .p-2.ms-2.me-2
                gc-user
              .p-2.ms-2.me-2
                a(href='#')
                  font-awesome-icon(:icon="['fas', 'phone']")
              .p-2.ms-2.me-2
                task-header
              .p-2.ms-2.me-2
                gc-rest-time
</template>
<script>
import TaskHeader from "./tasks/TaskHeader.vue"
import GcCall from "./calls/GcCall.vue"
import GcUser from "./users/GcUser.vue"
import GcExtension from "./pbx/GcExtension.vue"
import GcRestTime from "./rest_times/GcRestTime.vue"

export default {
  name: 'Header',
  components: {
    TaskHeader,
    GcCall,
    GcUser,
    GcRestTime,
    GcExtension
  },
  props: {
    darkTheme: {type: Boolean, default: false},
  },
  data() {
    return {
      mainMenu: [],
      user: {},
      newCompanyId: 0,
    }
  },
  computed: {
    companies() {
      let companies = [];
      if(this.user && this.user.companies) {
        this.user.companies.forEach((company) => {
          companies.push({id: company.id, label: company.name})
        })
      }

      return companies
    }
  },
  mounted() {
    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.user) {
      this.user = user.user
    }

    if(user && user.main_menu) {
      this.mainMenu = user.main_menu
    }
  },
  methods: {
    toogleTheme() {
      this.$emit("toogleTheme", "toogleTheme")
    },
    changeCompany() {
      this.$store.dispatch("changeCompany", { companyId: this.newCompanyId})
        .then(() => {
          location.reload()
        })
    }
  }
}
</script>
<style scoped>
  
</style>
