<template lang="pug">
  .call-item
    .border-top.p-1.small(:class="getCallBackground()" @mouseover="setHover(true)" @mouseleave="setHover(false)")
      .row.g-0
        .col-1.align-self-center.text-center
          span.icon.text-info(v-if="call.callType == 'in'")
            font-awesome-icon(icon="arrow-right")
          span.icon.text-success(v-else-if="call.callType == 'out'")
            font-awesome-icon(icon="arrow-left")
          span.icon.text-warning(v-else-if="call.callType == 'internal'")
            font-awesome-icon(icon="redo")
        .col-8.align-self-center.text-truncate(v-if="call.callType == 'in' || (user && call.callType == 'internal' && call.Exten == user.extension.number)")
          strong {{call.CallerIDNum}}
          br
          span {{call.CallerIDNum}}
        .col-8.align-self-center.text-truncate(v-else-if="call.callType == 'out' || (user && call.callType == 'internal' && call.CallerIDNum == user.extension.number)")
          strong {{call.Exten}}
          br
          span {{call.Exten}}
        .col-8.align-self-center.text-truncate(v-else)
          strong {{call.CallerIDNum}} {{call.Exten}}
          br
          span {{call.CallerIDNum}} {{call.Exten}}
        .col-3.align-self-center.text-end
          span(:key="'callStart-' + (user ? user.id : '')  + '-' + call.Linkedids[0] + '-' + counterKey") {{[call.start, "unix"] | unixToDateTimeEn | diffToNow | timeInHoursMin}}
      .row.g-0(v-if="hover")
        .col.small.pt-0.pb-0
          .d-flex.justify-content-around
            div
              button.btn.btn-link.p-0.text-dark(@click="parkMainChannel()" title="Aparcar llamada") 
                font-awesome-icon(:icon="['fas', 'parking']")
            div(v-if="user && currentUser.id == user.id")
              button.btn.btn-link.p-0.text-dark(@click="attendedTransferOwnChannel()" title="Transferencia atendida")
                font-awesome-icon(:icon="['fas', 'exchange-alt']")
            div(v-else)
              button.btn.btn-link.p-0.text-dark(@click="recoveryMainChannel()" title="Recuperar")
                font-awesome-icon(:icon="['fas', 'undo']")
            div
              button.btn.btn-link.p-0.text-dark(@click="redirectMainChannel()" title="Transferencia ciega")
                font-awesome-icon(:icon="['fas', 'retweet']")
            div
              button.btn.btn-link.p-0.text-dark(@click="hangupMainChannel()" title="Colgar llamada")
                font-awesome-icon(:icon="['fas', 'phone-slash']")
            div
              button.btn.btn-link.p-0.text-muted
                font-awesome-icon(:icon="['fas', 'headphones-alt']")
            div
              button.btn.btn-link.p-0.text-muted
                font-awesome-icon(:icon="['fas', 'headset']")

</template>

<script>

  export default {
    name: 'CallItem',
    props: { 
      call: Object,
      user: Object,
    },
    data () {
      return {
        hover: false,
        trunks: ['T1a', 'T1b', 'T2A', 'T2B', 'T4a', 'T4b'],
        isInTransfer: false,
      }
    },
    computed: {
      counterKey() {
        return this.$store.state.counterKey
      },
      currentUser() {
        return this.$store.state.currentUser
      },
      callState() {
        if(this.call.channels.every((c) => c.ChannelStateDesc == 'Up')) {
          return 'Activa'
        } else if(this.call.hold) {
          return 'Retenida'
        } else {
          return 'Inactiva'
        }
      },
      channelToRedirect() {
        return this.$store.state.callModule.channelToRedirect
      },
      channelToTransfer() {
        return this.$store.state.callModule.channelToTransfer
      },
      channelInTransfer() {
        return this.$store.state.callModule.channelInTransfer
      },
    },
    watch: {
      callState(newVal) {
        if(this.isInTransfer && newVal == 'Activa') {
          this.$store.commit("setChannelInTransfer", '')
          this.isInTransfer = false
        }
      },
      channelInTransfer(newVal, oldVal) {
        if(newVal != oldVal) {
          if(this.call.channels.some((c) => c.channel == newVal)) {
            this.isInTransfer = true
          }
        }
      }
    },
    beforeMount() {
      
    },
    methods: {
      setHover(hover) {
        this.hover = hover
      },
      getCallBackground() {
        let background = ''

        if(this.getMainChannel() == this.channelToRedirect || this.getOwnChannel() == this.channelToTransfer) {
          background = 'bg-info-opacity-25'
        } else if(this.call.hold) {
          background = 'bg-warning-opacity-25'
        } else if(!this.call.parked && this.call.channels.every((c) => c.ChannelStateDesc == 'Up')) {
          background = 'bg-primary-opacity-25'
        }

        return background
      },
      getMainChannel() {
        let getMainChannel = ''

        this.call.channels.forEach((channel) => {
          let indexSlash = channel.channel.indexOf('/')
          let indexDash = channel.channel.indexOf('-')

          // let channelType = channel.channel.substring(0, indexSlash)
          let channelName = channel.channel.substring(indexSlash + 1, indexDash)
          // let channelUnique = channel.channel.substring(indexDash + 1)

          let indexTrunk = this.trunks.findIndex(x => x == channelName)

          if(indexTrunk > -1) {
            getMainChannel = channel.channel
          }
        })

        return getMainChannel
      },
      getOwnChannel() {
        let getOwnChannel = ''

        this.call.channels.forEach((channel) => {
          let indexSlash = channel.channel.indexOf('/')
          let indexDash = channel.channel.indexOf('-')

          // let channelType = channel.channel.substring(0, indexSlash)
          let channelName = channel.channel.substring(indexSlash + 1, indexDash)
          // let channelUnique = channel.channel.substring(indexDash + 1)

          if(this.currentUser.extension && channelName == this.currentUser.extension.number) {
            getOwnChannel = channel.channel
          }
        })

        return getOwnChannel
      },
      hangupMainChannel() {
        let mainChannel = this.getMainChannel()

        if(mainChannel) {
          this.$store.dispatch("hangupChannel", mainChannel)
        }
      },
      parkMainChannel() {
        let mainChannel = this.getMainChannel()

        if(mainChannel) {
          this.$store.dispatch("parkChannel", mainChannel)
        }
      },
      recoveryMainChannel() {
        if(this.currentUser.extension && this.currentUser.extension.number) {
          let mainChannel = this.getMainChannel()

          if(mainChannel) {
            let data = {
              channel: mainChannel,
              extension: this.currentUser.extension.number
            }
            this.$store.dispatch("redirectChannel", data)
          }
        }
      },
      redirectMainChannel() {
        let mainChannel = this.getMainChannel()

        if(!this.channelToRedirect) {
          if(mainChannel) {
            this.$store.commit("setChannelToRedirect", mainChannel)
          }
        } else if(mainChannel == this.channelToRedirect) {
          this.$store.commit("setChannelToRedirect", '')
        }
      },
      attendedTransferOwnChannel() {
        let mainChannel = this.getOwnChannel()

        if(!this.channelToTransfer) {
          if(mainChannel) {
            this.$store.commit("setChannelToTransfer", mainChannel)
            //this.isInTransfer = true
          }
        } else if(mainChannel == this.channelToTransfer) {
          this.$store.commit("setChannelToTransfer", '')
          this.isInTransfer = false
        }
      }
      
    }
  }
</script>
<style lang="scss">
</style>